import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import login from '../../../assets/images/Login/login.svg';
import './login.scss';
import logo from '../../../assets/images/logo.avif';
import logoPng from '../../../assets/images/logo.png';
import shape from '../../../assets/images/Login/Shape.png';
import emailSvg from '../../../assets/images/Login/email.svg';
import passwordSvg from '../../../assets/images/Login/password.svg';
import showPassSvg from '../../../assets/images/Login/show_password.svg';
import hidePassSvg from '../../../assets/images/Login/hide_password.svg';
import ReCAPTCHA from 'react-google-recaptcha';
import { postRequest } from '../../../api/api';
import { POST_LOGIN } from '../../../api/endpoint';
import { ToastManager } from '../../../ToastContainer/ToastContainer';
import { SetLoggedInUserToken } from '../LoggedIn-Area/loggedInUserTokenUtils';
import { GetLoggedInUserToken } from '../LoggedIn-Area/loggedInUserTokenUtils';
import { Spinner } from 'react-bootstrap';
import { SetGuestUserToken } from '../GetQuote/guestUserTokenUtils.jsx';

const Login = () => {
  const [icon, Seticon] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captchaFilled, setCaptchaFilled] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmail = emailRegex.test(emailValue);
    setIsValidEmail(isValidEmail);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaFilled(true);
    } else {
      setCaptchaFilled(false);
    }
  };

  const isFormValid = () => {
    const isPasswordValid = password.trim().length >= 6;
    return isValidEmail && isPasswordValid && captchaFilled;
    // return isValidEmail && isPasswordValid;
  };

  const handleLogin = async () => {
    if (isFormValid()) {
      setIsLoading(true);
      const loginData = {
        user: {
          email: email,
          password: password,
        },
      };
      try {
        const { result, bearerToken } = await postRequest(
          POST_LOGIN,
          loginData
        );
        if (result.data) {
          const tokenWithoutBearer = bearerToken.replace('Bearer ', '');
          SetLoggedInUserToken(tokenWithoutBearer);
          SetGuestUserToken(bearerToken);
          if (GetLoggedInUserToken) {
            navigate('/account/info');
          }
        } else {
          ToastManager.error(result.error);
        }
      } catch (error) {
        console.error('An unexpected error occurred:', error);
      }
    } else {
      if (email === '') {
        ToastManager.error('Email is required');
      }
      if (password === '') {
        ToastManager.error('Password is required');
      }
      if (password.trim().length < 6) {
        ToastManager.error('Password should be of atleast 6 characters');
      }
      if (!captchaFilled) {
        ToastManager.error('Please fill reCAPTCHA');
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="">
        <div className="silo_page-container py-5 d-flex align-items-center">
          <div className="container position-relative">
            <img src={shape} className="dotted-lines" alt="dotted-lines" />
            <div className="row">
              <div className="col-sm py-2">
                <img
                  className="img-fluid login-form-img"
                  src={login}
                  alt="login_image"
                />
              </div>

              <div className="col-sm">
                <div className="contact-form-box">
                  <div>
                    <img
                      className="mb-4"
                      width="220"
                      src={logo}
                      alt="logo"
                      onError={(e) => {
                        // If AVIF fails to load, fallback to PNG
                        e.target.src = logoPng;
                      }}
                    />
                  </div>

                  <div className="mb-4">
                    <span>
                      Please enter your email & password to login your account.
                    </span>
                    <form id="account" method="post" className="mt-4">
                      <div className="form-group">
                        <div className="icon">
                          <img src={emailSvg} alt="email" />
                        </div>
                        <input
                          type="email"
                          className={`form-control mb-0 ${
                            isValidEmail ? '' : 'is-invalid'
                          }`}
                          placeholder="Enter email"
                          onChange={handleEmailChange}
                          value={email}
                        />
                        {!isValidEmail && (
                          <div className="invalid-feedback">
                            Please enter a valid email address.
                          </div>
                        )}
                      </div>
                      <div className="form-group mt-3">
                        <div className="icon">
                          <img src={passwordSvg} alt="password" />
                        </div>
                        <input
                          type={icon ? 'password' : 'text'}
                          className="form-control"
                          placeholder="Password"
                          value={password}
                          onChange={handlePasswordChange}
                        />
                        <div className="icon" onClick={() => Seticon(!icon)}>
                          <img
                            src={icon ? showPassSvg : hidePassSvg}
                            alt="showPassword"
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="form-check m-0">
                          <input type="checkbox" className="form-check-input" />
                          <label
                            className="form-check-label"
                            htmlFor="exampleCheck1"
                          >
                            Remember me
                          </label>
                        </div>
                        <Link to="/forgetPassword">Forgot Password ?</Link>
                      </div>
                      <div
                        className="form-group"
                        data-testid="recaptcha-placeholder"
                      >
                        {<ReCAPTCHA
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                          onChange={handleCaptchaChange}
                          />}
                      </div>
                      <span
                        className="mt-4 btn silo-btn silo-btn__blue"
                        onClick={handleLogin}
                      >
                        {isLoading ? <Spinner size="sm" /> : <>LOGIN</>}
                      </span>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
