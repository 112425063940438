import './App.scss';
import React from 'react';
import { ContextProvider } from './utils/ContextProvider';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './components/pages/Home/Home';
import About from './components/pages/About/About';
import Login from './components/pages/Login/Login';
import Navigationbar from './components/Navbar/Navbar';
import LoggedInNavigationbar from './components/Navbar/LoggedInAreaNavBar';
import Footer from './components/Footer/Footer';
import TermsAndConditions from './components/pages/TermsAndConditions/TermsAndConditions';
import SurplusLinesDisclosure from './components/pages/SurplusLinesDisclosure/SurplusLinesDisclosure';
import ForgetPassword from './components/pages/ForgetPassword/ForgetPassword';
import ForgetPasswordConfirmation from './components/pages/ForgetPasswordConfirmation/ForgetPasswordConfirmation';
import Policy from './components/pages/Privacy-Policy/Privacy-Policy';
import GetQuote from './components/pages/GetQuote/GetQuote';
import VehicleDetails from './components/pages/GetQuote/VehicleDetails/VehicleDetails';
import ChoosePlan from './components/pages/GetQuote/ChoosePlan/ChoosePlan';
import MemberInformation from './components/pages/GetQuote/MemberInformation/MemberInformation';
import Payment from './components/pages/GetQuote/Payment/Payment';
import ContactUs from './components/pages/ContactUs/ContactUs';
import FAQ from './components/pages/ContactUs/FAQ/FAQ';
import Support from './components/pages/ContactUs/Support/Support';
import NonMadatoryVehicleDetails from './components/pages/GetQuote/VehicleDetails/Section/NonMadatoryVehicleDetails/NonMandatoryVehicleDetails';
// import ChatButton from './components/LiveChat/ChatButton';
import Billing from './components/pages/LoggedIn-Area/Account/Billing/Billing';
import ProfileSection from './components/pages/LoggedIn-Area/Account/Personel-info/ProfileSection';
import Manage from './components/pages/LoggedIn-Area/Account/Policy-manage/Policy';
import Account from './components/pages/LoggedIn-Area/Account/Account';
import EditAccount from './components/pages/LoggedIn-Area/Account/Personel-info/Edit/EditAccount';
import VehicleInfo from './components/pages/LoggedIn-Area/Account/VehicleInfo/VehicleInfo';
import AddNewVehicle from './components/pages/LoggedIn-Area/Account/VehicleInfo/Section/NewVehicle/NewVehicle'; 
import DriverInfo from './components/pages/LoggedIn-Area/Account/DriverInfo/DriverInfo';
import AddNewDriver from './components/pages/LoggedIn-Area/Account/DriverInfo/Section/AddDriver/AddNewDriver'
import BillingDetails from './components/pages/LoggedIn-Area/Account/Billing/Section/BillingDetails/BillingDetails';
import BillingHistory from './components/pages/LoggedIn-Area/Account/Billing/Section/BillingHistory/BillingHistory';
import ManageSubscription from './components/pages/LoggedIn-Area/Account/Billing/Section/ManageSubs/ManageSubscription';
import EditVehicle from './components/pages/LoggedIn-Area/Account/VehicleInfo/Section/ActiveVehicle/Section/EditVehicle/EditVehicle';
import EditDriver from './components/pages/LoggedIn-Area/Account/DriverInfo/Section/ActiveDriver/Section/EditDriver/EditDriver';
import NotFound from './components/404/NotFound';
import EditBilling from './components/pages/LoggedIn-Area/Account/Billing/Section/BillingDetails/Section/Edit/EditBilling';
import ResetPassword from './components/pages/ResetPassword/ResetPassword';
import Logout from './components/pages/LoggedIn-Area/Account/Sidebar/Logout/Logout';
import PaymentSuccess from './components/pages/GetQuote/Payment/Section/PaymentSuccess/PaymentSuccess';
import PaymentIncomplete from './components/pages/GetQuote/Payment/Section/PaymentIncomplete/PaymentIncomplete';
import PaymentFailed from './components/pages/GetQuote/Payment/Section/PaymentFailed/PaymentFailed';
import NotLoggedIn from './components/pages/LoggedIn-Area/NotLoggedIn';
import { LoggedinUserContextProvider } from './utils/LoggedinUserContextProvider';
import CertificateOfInsurance from './components/pages/LoggedIn-Area/Account/UserDocuments/CertificateOfInsurance';
import Documents from './components/pages/LoggedIn-Area/Account/UserDocuments/Documents';
import DotPage from './components/pages/ClientRegisteration/ClientDOTNumber';
import AgencyReferral from './components/pages/ClientRegisteration/AgencyReferral';

const App = () => {
  const token = localStorage.getItem('loggedInUserToken');
  const location = useLocation();
  const isAccountRoute = location.pathname.startsWith('/account');

  return (
    <>
      <div className="App">
        <LoggedinUserContextProvider>
          <ContextProvider>
            {!isAccountRoute && (
              <Navigationbar isLoggedIn={token ? true : false} />
            )}
            {isAccountRoute && <LoggedInNavigationbar />}
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/" element={<Home />} />
              <Route path="about" element={<About />} />
              {token ? (
                <Route path="login" element={<Navigate to="/account/info" />} />
              ) : (
                <Route path="login" element={<Login />} />
              )}
              <Route path="forgetPassword" element={<ForgetPassword />} />
              <Route path="resetpassword" element={<ResetPassword />} />
              <Route path="account/logout" element={<Logout />} />
              <Route path="payment-success" element={<PaymentSuccess />} />
              <Route
                path="payment-incomplete"
                element={<PaymentIncomplete />}
              />
              <Route path="payment-failure" element={<PaymentFailed />} />
              <Route
                path="forgetPasswordConfirmation"
                element={<ForgetPasswordConfirmation />}
              />
              <Route
                path="termsandconditions"
                element={<TermsAndConditions />}
              />

              <Route
                path="surplus-lines-disclosure"
                element={<SurplusLinesDisclosure />}
              />

              <Route path="privacyPolicy" element={<Policy />} />
              <Route path="quote/" element={<GetQuote />}>
                <Route path="vehicle-details" element={<VehicleDetails />} />
                <Route path="choose-plan" element={<ChoosePlan />} />
                <Route
                  path="member-information"
                  element={<MemberInformation />}
                />
                <Route path="payment" element={<Payment />} />
              </Route>
              <Route path="/dot/:dotNumber" element={<DotPage />} />
              <Route path="/producer/:producerName" element={<AgencyReferral />} />
              <Route path="contact" element={<ContactUs />} />
              {/* Render App router inside the /Account route */}
              <Route
                path="account"
                element={token ? <Account /> : <NotLoggedIn />}
              >
                <Route path="billing" element={<Billing />} />
                <Route path="billing/details" element={<BillingDetails />} />
                <Route path="billing/details/edit" element={<EditBilling />} />
                <Route path="billing/history" element={<BillingHistory />} />
                <Route
                  path="billing/subscription"
                  element={<ManageSubscription />}
                />
                <Route path="info" element={<ProfileSection />} />
                <Route path="policy" element={<Manage />} />
                <Route path="info/edit" element={<EditAccount />} />
                <Route path="driver-info" element={<DriverInfo />} />
                <Route path="vehicle-info" element={<VehicleInfo />} />
                <Route path="/account/vehicle-info/new-vehicle" element={<AddNewVehicle />} />
                <Route path="/account/driver-info/add-new-driver" element={<AddNewDriver />} />
                <Route path="vehicle-info/edit" element={<EditVehicle />} />
                <Route path="driver-info/edit" element={<EditDriver />} />
                <Route
                  path="certificate-of-insurance"
                  element={<CertificateOfInsurance />}
                />
                <Route path="documents" element={<Documents />} />
              </Route>
              <Route path="contact/faq" element={<FAQ />} />
              <Route path="contact/support" element={<Support />} />
              <Route
                path="vehicle-details-2"
                element={<NonMadatoryVehicleDetails />}
              />
            </Routes>
            {/* <ChatButton /> */}
            <Footer />
          </ContextProvider>
        </LoggedinUserContextProvider>
      </div>
    </>
  );
};

export default App;
