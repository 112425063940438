import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../../../../../utils/Context';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import '../../../../../GetQuote/VehicleDetails/VehicleDetails';
import VIN from '../../../../../../../../src/components/pages/GetQuote/VehicleDetails/Section/VIN/VIN';
import ManualDetails from '../../../../../../../../src/components/pages/GetQuote/VehicleDetails/Section/ManualDetails/ManualDetails';
import NonMadatoryVehicleDetails from '../../../../../../../../src/components/pages/GetQuote/VehicleDetails/Section/NonMadatoryVehicleDetails/NonMandatoryVehicleDetails';
import DOTInfo from '../../../../../../../../src/components/pages/GetQuote/VehicleDetails/Section/DOTInfo/DOTInfo';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Arrowsvg from '../../../../../../../assets/images/Form/Arrow.svg';
import { GET_VEHICLE_DETAILS } from '../../../../../../../api/endpoint';
import { getRequest } from '../../../../../../../api/api';
import { useNavigate } from 'react-router-dom';
import { GetGuestUserToken } from '../../../../../../../../src/components/pages/GetQuote/guestUserTokenUtils';

const VehicleDetails = ({ current, setCurrent, saveProfile }) => {
  const location = useLocation();
  const wasRedirected = location.state && location.state.fromRedirected;
  const navigate = useNavigate();

  const {
    vehicles,
    setVehicles,
    profileObject,
    setProfileObject,
    setUserEmail,
    setUserId,
    setDisableState,
  } = useContext(Context);
  const [showSection1, setShowSection1] = useState(true);
  const [showSection2, setShowSection2] = useState(false);
  const [showDotInfo, setshowDotInfo] = useState(false);
  const [activeTab, setActiveTab] = useState('second');
  const [editVehicleIndex, setEditVehicleIndex] = useState(null);
  const [unitType, setUnitType] = useState('powerUnit');
  const [step, setStep] = useState(1); // Variable to control the step

  /* Mandatory Vehicle Details */
  const [cargo, setCargo] = useState(null);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    getVehicleDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const guestUserToken = GetGuestUserToken();
    if (profileObject && guestUserToken === null) {
      navigate('/account/vehicle-info/new-vehicle', { replace: true });
      window.location.reload();
    } else if (profileObject && profileObject?.is_verified) {
      window.scrollTo(0, 0);
      navigate('/quote/payment');  // Put this when finish -> navigate('/quote/payment');  --> //to test this /account/vehicle-info/new-vehicle
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileObject]);

  const getVehicleDetails = async () => {
    try {
      const response = await getRequest(GET_VEHICLE_DETAILS);
      setProfileObject(
        response.result.profiles && response?.result?.profiles[0]
      );
      setUserEmail(response?.result?.email);
      setUserId(response?.result?.id);
      response.result.profiles &&
        setVehicles(
          ...response?.result?.profiles.map(
            (profile) => profile.vehicle_details
          )
        );
    } catch (error) {
      console.log('API error:', error);
    }
  };

  const addNewVehicle = (unitType, vehicleIndex) => {
    setDisableState(false);
    setUnitType(unitType);
    setVehicles((prevVehicles) => [...prevVehicles, {}]);
    setShowSection1(true);
    setShowSection2(false);
    setshowDotInfo(false);
    setActiveTab('second');
    setEditVehicleIndex(null);
  };

  const saveVehicle = async () => {
    try {
      const vehicleData = {
        vin_number: vehicles[editVehicleIndex]?.vin_number,
        license_plate_number: vehicles[editVehicleIndex]?.license_plate_number,
        license_state_id: vehicles[editVehicleIndex]?.license_state_id,
        vehicle_body_type: vehicles[editVehicleIndex]?.vehicle_body_type,
        vehicle_make: vehicles[editVehicleIndex]?.vehicle_make,
        vehicle_model: vehicles[editVehicleIndex]?.vehicle_model,
        vehicle_year: vehicles[editVehicleIndex]?.declared_year,
        cargo_commodities: vehicles[editVehicleIndex]?.cargo_commodities,
      };

      // Aquí llamarías a la API para guardar el vehículo
      await saveProfile(vehicleData);
      console.log('Vehicle saved successfully');
    } catch (error) {
      console.error('Error saving vehicle:', error);
    }
  };

  const handleNextStep = () => {
    if (step === 1) {
      setShowSection1(false);
      setShowSection2(true);
      setStep(2);
    } else if (step === 2) {
      setShowSection2(false);
      setshowDotInfo(true);
      setStep(3);
    } else if (step === 3) {
      saveVehicle();
    }
  };

  return (
    <>
      {vehicles &&
        vehicles.map((vehicle, vehicleIndex) => {
          const isLatestVehicle = vehicleIndex === vehicles.length - 1;
          return (
            <Row className={`mb-3`} key={vehicleIndex}>
              <Col
                lg={8}
                className={`${
                  (isLatestVehicle && editVehicleIndex === null) ||
                  editVehicleIndex === vehicleIndex
                    ? ''
                    : 'd-none'
                }`}
              >
                <div className="current-container mb-3 mb-md-5">
                  {wasRedirected && (
                    <Link
                      to={'/account/vehicle-info'}
                      className="mt-5 text-decoration-none"
                    >
                      <span className="float-start back-btn-style">
                        <img src={Arrowsvg} className="pe-2" alt="" />
                        <span className="mt-5 text-blue">
                          Back to Vehicle Info
                        </span>
                      </span>
                    </Link>
                  )}
                  {showSection1 && (
                    <div className="">
                      <h3 className="fw-bold">Please add a new Vehicle</h3>
                      <Container>
                        <Tab.Container
                          defaultActiveKey={
                            vehicle.vin_number && vehicle.vin_number.length > 0
                              ? 'first'
                              : 'second'
                          }
                        >
                          <Row>
                            <Nav variant="pills" className="">
                              <Nav.Item className="tab-button fw-bold fs-6">
                                <Nav.Link
                                  eventKey="second"
                                  style={{ paddingLeft: '10px' }}
                                >
                                  Without VIN
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item className="tab-button fw-bold fs-6">
                                <Nav.Link
                                  eventKey="first"
                                  style={{ paddingLeft: '10px' }}
                                >
                                  With VIN
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Row>
                          <Row>
                            <Tab.Content>
                              <Tab.Pane eventKey="first">
                                <VIN
                                  setShowSection2={setShowSection2}
                                  setShowSection1={setShowSection1}
                                  activeTab={activeTab}
                                  vehicleIndex={vehicleIndex}
                                  setCargo={setCargo}
                                  saveProfile={saveProfile}
                                  profileObject={profileObject}
                                />
                              </Tab.Pane>
                              <Tab.Pane eventKey="second">
                                <ManualDetails
                                  setShowSection2={setShowSection2}
                                  setShowSection1={setShowSection1}
                                  activeTab={activeTab}
                                  vehicleIndex={vehicleIndex}
                                  profile={profile}
                                  setProfile={setProfile}
                                  cargo={cargo}
                                  setCargo={setCargo}
                                  saveProfile={saveProfile}
                                  unitType={unitType}
                                  profileObject={profileObject}
                                />
                              </Tab.Pane>
                            </Tab.Content>
                          </Row>
                        </Tab.Container>
                      </Container>
                    </div>
                  )}
                  {showSection2 && (
                    <div className="">
                      <h3 className="fw-bold">Tell us more about your Vehicle...?</h3>
                      <Container className="plr-0-sm">
                        <NonMadatoryVehicleDetails
                          setShowSection2={setShowSection2}
                          setshowDotInfo={setshowDotInfo}
                          setShowSection1={setShowSection1}
                          vehicleIndex={vehicleIndex}
                          saveProfile={saveProfile}
                        />
                      </Container>
                    </div>
                  )}
                  {showDotInfo && (
                    <div>
                      <h3 className="fw-bold">Select Vehicle DOT Information</h3>
                      <Container>
                        <DOTInfo
                          setShowSection2={setShowSection2}
                          setshowDotInfo={setshowDotInfo}
                          vehicleIndex={vehicleIndex}
                          addNewVehicle={addNewVehicle}
                          saveProfile={saveProfile}
                        />
                      </Container>
                    </div>
                  )}
                  <div className="text-end">
                    <button
                      onClick={handleNextStep}
                      className="btn btn-primary"
                    >
                      {step === 3 ? 'Save' : 'Next'}
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}
    </>
  );
};

export default VehicleDetails;
