import React, { useState } from 'react';
import MembershipDetails from '../../../../../../../../src/components/pages/GetQuote/MemberInformation/Section/MembershipDetails/MembershipDetails'
import { Row, Col } from 'react-bootstrap';

const AddNewDriver = ({ saveProfile }) => {
  const [setShowUpdateVINSection] = useState(false);
  const [Setpassword] = useState();

  return (
    <div className="container-fluid mt-md-0 mt-3 plr-0-sm">
      <Row>
        <Col lg={8} className="my-md-3 my-3 ">
          <MembershipDetails
                saveProfile={saveProfile}
                Setpassword={Setpassword}
                setShowUpdateVINSection={setShowUpdateVINSection}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AddNewDriver;
