import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import ActiveDriver from './Section/ActiveDriver/ActiveDriver';
import NonActiveDriver from './Section/NonActiveDriver/NonActiveDriver';
// import { AiOutlinePlus } from 'react-icons/ai';
import '../DriverInfo/driverInfo.scss';
import { useNavigate } from 'react-router-dom'; 


const DriverInfo = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('activeDriver');

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  const redirectToAddNewDriver = () => {
    navigate('/account/driver-info/add-new-driver', { state: { fromRedirected: true } });
  };

  return (
    <div>
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <h3 className='mb-0'>
            <strong>Driver Info</strong>
          </h3>
        </div>
        <hr />        
        <div className="d-flex justify-content-end w-100" style={{ marginBottom: '10px' }}>
          <span
            className="btn silo-btn-small silo-btn__blue btn-primary"
            onClick={redirectToAddNewDriver}
          >
            <span>Add Driver</span>
          </span>
        </div>
  
        <div className='tabs-parent'>
          <Tab.Container activeKey={activeTab}>
            <Nav
              variant="pills"
              className="text-center"
            >
              <Nav.Item className="tab-button fw-bold fs-6">
                <Nav.Link
                  eventKey="activeDriver"
                  onClick={() => handleTabChange('activeDriver')}
                >
                  Active Driver
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab-button fw-bold fs-6">
                <Nav.Link
                  eventKey="nonActiveDriver"
                  onClick={() => handleTabChange('nonActiveDriver')}
                >
                  Non Active Driver
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="activeDriver">
                <ActiveDriver />
              </Tab.Pane>
              <Tab.Pane eventKey="nonActiveDriver">
                <NonActiveDriver />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
}  

export default DriverInfo;
