import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import ActiveVehicle from './Section/ActiveVehicle/ActiveVehicle';
import NonActiveVehicle from './Section/NonActiveVehicle/NonActiveVehicle';
//import { AiOutlinePlus } from 'react-icons/ai'; 
import '../VehicleInfo/vehicleInfo.scss';
import { useNavigate } from 'react-router-dom'; 

const VehicleInfo = (props) => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('activeVehicle');

  const handleTabChange = (tabKey) => {
    setActiveTab(tabKey);
  };

  const redirectToAddNewVehicle = () => {
    navigate('/account/vehicle-info/new-vehicle', { state: { fromRedirected: true } });
  };

  return (
    <div>
      <h3>
        <b>Vehicle Info</b>
      </h3>
      <hr />
      <div>
        <div className="d-flex align-items-center justify-content-between">
          
          {/* <div>
            <button
              className="btn silo-btn-small silo-btn__white mx-2"
            >
              <FaFileDownload /> Download Certificates
            </button>
          </div> */}
          
          <div className="d-flex justify-content-end w-100">
            <span
              className="btn silo-btn-small silo-btn__blue btn-primary" style={{ marginBottom: '10px' }}
              onClick={redirectToAddNewVehicle}
            >
              {/* <AiOutlinePlus /> */}
              <span>Add Vehicle</span>
            </span>
          </div>
        </div>
        <div className='tabs-parent'>
          <Tab.Container activeKey={activeTab}>
            <Nav
              variant="pills"
              className="text-center"
            >
              <Nav.Item className="tab-button fw-bold fs-6">
                <Nav.Link
                  eventKey="activeVehicle"
                  onClick={() => handleTabChange('activeVehicle')}
                >
                  Active Vehicle
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="tab-button fw-bold fs-6">
                <Nav.Link
                  eventKey="nonActiveVehicle"
                  onClick={() => handleTabChange('nonActiveVehicle')}
                >
                  Non Active Vehicle
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="activeVehicle">
                <ActiveVehicle />
              </Tab.Pane>
              <Tab.Pane eventKey="nonActiveVehicle">
                <NonActiveVehicle />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default VehicleInfo;
